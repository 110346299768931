import {createSlice} from "@reduxjs/toolkit";
import e3Style from '../../css/e3-style.css';
import {createSelector} from "reselect";
import {apiDoCall} from "./api";
import axios from "axios";
import {font_scale_100} from "../components/popups/windowPrefs";
import {updateActTime} from "./activity";

export const colorSelectionMap = new Map();
colorSelectionMap.set("no-class", null); // default "black-on-white"
colorSelectionMap.set("sprint-black-white", e3Style["sprint-black-white"]);
colorSelectionMap.set("sprint-blue-yellow", e3Style["sprint-blue-yellow"]);
colorSelectionMap.set("sprint-black-yellow", e3Style["sprint-black-yellow"]);
colorSelectionMap.set("sprint-green-white", e3Style["sprint-green-white"]);

export const sizeSelectionMap = new Map();
sizeSelectionMap.set("sprint-font-18", e3Style["sprint-font-18"]);
sizeSelectionMap.set("sprint-font-28", e3Style["sprint-font-28"]);
sizeSelectionMap.set("sprint-font-36", e3Style["sprint-font-36"]);
sizeSelectionMap.set("no-class", null); // default 60
sizeSelectionMap.set("sprint-font-72", e3Style["sprint-font-72"]);

export const scaleSelectionMap = new Map();
scaleSelectionMap.set("font-scale-100", e3Style["font-scale-100"]);
scaleSelectionMap.set("font-scale-150", e3Style["font-scale-150"]);
scaleSelectionMap.set("font-scale-200", e3Style["font-scale-200"]);

const slice = createSlice({
	name: "display",
	initialState: {
		colorSelection: "no-class",
		sizeSelection: "no-class",
		scaleSelection: "font-scale-100",
		autoSend: false,
		locale: "en",
		brailleUser: false,
		deafBlind: false
	},
	reducers: {
		setDisplayPrefs: (display, action) => {
			display.colorSelection = action.payload.response.response.colorSelection;
			display.sizeSelection = action.payload.response.response.sizeSelection;
			display.scaleSelection = action.payload.response.response.scaleSelection;
			display.autoSend = action.payload.response.response.autoSend;
			display.locale = action.payload.response.response.locale;
			display.brailleUser = action.payload.response.response.brailleUser;
			display.deafBlind = action.payload.response.response.deafBlind;
		},
		resetDisplayPrefs: (display, action) => {
			display.colorSelection = "no-class";
			display.sizeSelection = "no-class";
			display.scaleSelection = font_scale_100;
			display.autoSend = false;
			display.locale = "en";
			display.brailleUser = false;
			display.deafBlind = false;
		},
		setColorSel: (display, action) => {
			display.colorSelection = action.payload.custom.colorSelection;
		},
		setSizeSel: (display, action) => {
			display.sizeSelection = action.payload.custom.sizeSelection;
		},
		setScaleSel: (display, action) => {
			display.scaleSelection = action.payload.custom.scaleSelection;
		},
		setBrailleUserRdcr: (display, action) => {
			display.brailleUser = action.payload.custom.brailleUser;
		},
		setDeafBlindRdcr: (display, action) => {
			display.deafBlind = action.payload.custom.deafBlind;
		},
		setAutoSendRdcr: (display, action) => {
			display.autoSend = action.payload.custom.autoSend;
		}
	}
});

export const {
	setDisplayPrefs,
	resetDisplayPrefs,
	setColorSel,
	setSizeSel,
	setScaleSel,
	setBrailleUserRdcr,
	setDeafBlindRdcr,
	setAutoSendRdcr
} = slice.actions;
export default slice.reducer;

export const loadDisplaySettings = (dispatch, csrfSessionNonce, callbackFunc) => {
	return dispatch(
		apiDoCall({
			data: {
				command: {name: 'getDisplaySettings', csrfSessionNonce}
			},
			onSuccess: [{type: setDisplayPrefs.type}, callbackFunc(true)],
			onError: callbackFunc(false)
		})
	);
};

export const saveDisplaySettings = async (dispatch, settings, csrfSessionNonce, callbackFunc) => {
	try {
		updateActTime(dispatch);
		const response = await axios.post('/nologin/api',
			{
				command: {name: 'setDisplaySettings', csrfSessionNonce},
				params: settings
			}, {timeout: 5000});
		callbackFunc(dispatch, response);
	} catch (error) {
		callbackFunc(dispatch, null, error);
	}
};

export const setSizeSelection = (dispatch, sizeSelection) => {
	dispatch({type: setSizeSel.type, payload: {custom: {sizeSelection}}});
};

export const setColorSelection = (dispatch, colorSelection) => {
	dispatch({type: setColorSel.type, payload: {custom: {colorSelection}}});
};

export const setScaleSelection = (dispatch, scaleSelection) => {
	dispatch({type: setScaleSel.type, payload: {custom: {scaleSelection}}});
};

export const setBrailleUser = (dispatch, brailleUser) => {
	dispatch({type: setBrailleUserRdcr.type, payload: {custom: {brailleUser}}});
};

export const setDeafBlind = (dispatch, deafBlind) => {
	dispatch({type: setDeafBlindRdcr.type, payload: {custom: {deafBlind}}});
};

export const setAutoSend = (dispatch, autoSend) => {
	dispatch({type: setAutoSendRdcr.type, payload: {custom: {autoSend}}});
};

export const getHtmlClasses = (color, size) => {
	let clsArry = [];
	const colorClass = colorSelectionMap.get(color);
	colorClass && clsArry.push(colorClass);
	const sizeClass = sizeSelectionMap.get(size);
	sizeClass && clsArry.push(sizeClass);
	return clsArry.join(" ");
};

export const getDisplayClasses = createSelector(
	state => state.entities.display.colorSelection,
	state => state.entities.display.sizeSelection,
	(color, size) => ({color, size})
);

export const getFontScaleClass = createSelector(
	state => state.entities.display.scaleSelection,
	scale => scale
);

export const getDisplayPrefSettings = createSelector(
	state => state.entities.display.colorSelection,
	state => state.entities.display.sizeSelection,
	state => state.entities.display.scaleSelection,
	state => state.entities.display.locale,
	state => state.entities.display.autoSend,
	(colorSelection, sizeSelection, scaleSelection, locale, autoSend) => ({colorSelection, sizeSelection, scaleSelection, locale, autoSend})
);

export const getChatAreaPrefs = createSelector(
	state => state.entities.display.brailleUser,
	state => state.entities.display.deafBlind,
	state => state.entities.display.autoSend,
	(brailleUser, deafBlind, autoSend) => ({isLowVision: brailleUser || deafBlind, autoSend})
);

export const isLowVision = createSelector(
	state => state.entities.display.brailleUser,
	state => state.entities.display.deafBlind,
	(brailleUser, deafBlind) => brailleUser || deafBlind
);

export const isDeafBlind = createSelector(
	state => state.entities.display.deafBlind,
	deafBlind => deafBlind
);
