import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {getChatAreaPrefs} from "../../store/display";
import sprStyle from "../../../css/sprint-style.css";
import e3Style from "../../../css/e3-style.css";

const ChatTextArea = ({userText, setUserText, sendUserText, isReadOnly}) => {
	const ref = useRef();
	const chatPrefs = useSelector(getChatAreaPrefs);
	const handleChange = ({currentTarget: ct}) => {
		if (chatPrefs.autoSend) {
			const leadingChars = /^(.+\s)(.*)/;
			const result = leadingChars.exec(ct.value);
			if (result) {
				const textToSend = result[1];
				if (textToSend) {
					sendUserText(textToSend);
					setUserText(result[2]);
				} else setUserText(ct.value);
			} else setUserText(ct.value);
		} else setUserText(ct.value);
	};
	const keyDown = e => {
		if (e.keyCode === 13) {
			e.preventDefault();
			sendUserText(userText);
			setUserText('');
		}
	};

	useEffect(() => {
		if (isReadOnly) setUserText("");
		else setTimeout(() => ref?.current?.focus(), 200);
	}, [isReadOnly]);

	return chatPrefs.isLowVision ?
		<input type="text" id="chat-text-area" ref={ref} className={sprStyle['impt-black']} readOnly={isReadOnly}
		       value={userText} onChange={handleChange} onKeyDown={keyDown}/>
		:
		<textarea id="chat_text_area" ref={ref} className={`${sprStyle['impt-black']} ${e3Style.chat_text_cls}`}
		          rows="2" cols="50" readOnly={isReadOnly} value={userText} onChange={handleChange} onKeyDown={keyDown}/>
};

export default ChatTextArea;
