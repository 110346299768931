import React from "react";
import {AGENT_SOURCE} from "../../store/convoLog";
import {useSelector} from "react-redux";
import {getCallState} from "../../store/call";
import {dispPhoneNum} from "../util/stringFormatHelper";
import {isLowVision} from "../../store/display";
import e3Style from '../../../css/e3-style.css';
import sprStyle from '../../../css/sprint-style.css';

const LOW_VIS_LINE_SZ = 40;

const ConvoEntry = ({entry}) => {
	const callState = useSelector(getCallState);
	const isLowVis = useSelector(isLowVision);
	const srcLbl = entry.source === AGENT_SOURCE ? dispPhoneNum(callState.callPhoneNumber) : 'Me';
	const wrapEntry = () => {
		const txtArray = entry.text.toLowerCase().split(" ");
		const lines = [];
		const leadTxt = srcLbl + ":";
		let lineTxt = leadTxt;
		for (let i=0;i<txtArray.length;++i) {
			const nxtWord = txtArray[i];
			if (i === 0 || lineTxt.length + nxtWord.length + 1 <= LOW_VIS_LINE_SZ) {
				lineTxt += " " + nxtWord;
			} else {
				lines.push(lineTxt);
				lineTxt =  nxtWord;
			}
		}
		lines.push(lineTxt);
		const linesJsx = lines.map((entry, index) => <div key={index} className={`${sprStyle['chat_text_entry']} ${sprStyle['std-txt-150pct']} ${e3Style['ma-0']}`}>{entry}</div>);
		return <>{linesJsx}</>;
	};
	return isLowVis ? wrapEntry() :
		<div className={e3Style['chat_text_entry']}><span>{srcLbl}</span><p>{entry.text}</p></div>;
};

export default ConvoEntry;
